<template>
    <div class="instance">
        <!-- <div class="header-bg"></div> -->
        <p class="title-en">
            <strong>COMPANY STRENGTH</strong>
        </p>
        <div class="instance-header">
            <p class="title-cn"><strong>我们的实力</strong></p>
            <div class="line-color"></div>
            <p class="title-text">
                自成立以来，一直致力于为智慧水务、海绵城市建设、城市内涝及水环境改善提供整体解决方案
            </p>
            <div class="box-list">
                <div class="box-list-item box-bg1">
                    <div class="box-list-list">
                        <p>● 国家高新技术企业</p>
                        <p>● 测绘资质乙级</p>
                        <p>● 市政公用工程施工总承包三级</p>
                        <p>● 管道检测检验检测机构资质认定</p>
                        <p>● ISO管理体系认证</p>
                        <p>● 劳务分包资质证书</p>
                        <p>● 10+ 发明专利及实用新型专利</p>
                    </div>
                    <p class="box-item-title">
                        <strong>公司资信力量</strong
                        ><span>Company credit strength</span>
                    </p>
                </div>
                <div class="box-list-item box-bg2">
                    <div class="box-list-list">
                        <p>
                            ●
                            20+国家及地方技术标准、规范、指引，涵盖海绵城市、智慧水务、水务监测及管网运维工作
                        </p>
                        <p>
                            ●
                            为给水、污水、雨水等各类管网系统提供数据拓扑型分析、数据补测、数据准确性核定、数据模型建立、缺陷检测等服务
                        </p>
                    </div>
                    <p class="box-item-title">
                        <strong>数据标准建设</strong
                        ><span>Data standard construction</span>
                    </p>
                </div>
                <div class="box-list-item box-bg3">
                    <div class="box-list-list">
                        <p>
                            ●
                            致力于智慧水务专业性平台开发、水务数据模型开发、基础理论研究及水务监测工作
                        </p>
                        <p>
                            ●
                            提供海绵城市从规划、设计咨询，到施工、产品全过程的解决方案
                        </p>
                    </div>
                    <p class="box-item-title">
                        <strong>智慧水务建设</strong
                        ><span>Smart Water Construction</span>
                    </p>
                </div>
                <div class="box-list-item box-bg4">
                    <div class="box-list-list">
                        <p>
                            ●
                            自主开发移动端数据采集软件：通过可视化现场作业、数据查看统计和水务定位导航接入等功能设计，实现了全流程的可视化数据采集及管理
                        </p>
                    </div>
                    <p class="box-item-title">
                        <strong>城市水务运维</strong
                        ><span>Urban water operation and maintenance</span>
                    </p>
                </div>
                <div class="box-list-item box-bg5">
                    <div class="box-list-list">
                        <p>
                            ●
                            提供海绵城市监测、城市面源污染控制产品一体化及地下综合管廊建设解决方案。
                        </p>
                        <p>
                            ●
                            主要产品包括环保雨水口、排水路缘石、透水砖及透水混凝土、线性排水沟、雷达液位计、声学多普勒流量计、树脂混凝土缆线管廊等。
                        </p>
                    </div>
                    <p class="box-item-title">
                        <strong>水务产品研发</strong
                        ><span>Water product development</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BannerInstance",
    methods: {
        lineTo(data) {
            this.$parent.$parent.$children[0].lineTo(data);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/mixin.scss";
.instance {
    width: 100%;
    height: 100%;
    // margin: 100px auto 0;
    .title-en {
        // color: rgba(13, 39, 134, 0.24);
        color: #ffffff5e;
        font-size: 72px;
        text-align: center;
        white-space: normal;
        margin-top: 100px;
    }
    .instance-header {
        width: 1600px;
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translatex(-50%);
        .title-cn {
            color: #fff;
            font-size: 36px;
            text-align: center;
            white-space: normal;
        }
        .line-color {
            width: 77px;
            height: 4px;
            margin: 10px auto;
            background: #fff;
        }
        .title-text {
            text-align: center;
            font-size: 16px;
            color: #fff;
        }
        .box-list {
            display: flex;
            justify-content: space-between;
            width: 1600px;
            margin-top: 20px;
            .box-list-item {
                width: 280px;
                height: 540px;
                // background: #ffffff40;
                color: #fff;
                // color: #00000050;
                .box-list-list {
                    height: 400px;
                    line-height: 22px;
                    padding: 30px 15px;
                    color: #fff;
                    background: #0d2786c4;
                    font-size: 16px;
                    // text-align: left;
                    box-sizing: border-box;
                    opacity: 0;
                    cursor: pointer;
                    p {
                        margin-bottom: 7px;
                    }
                }
                .box-list-list:hover {
                    opacity: 1;
                }
                .box-item-title {
                    display: flex;
                    flex-direction: column;
                    padding-top: 45px;
                    height: 140px;
                    font-size: 24px;
                    text-align: center;
                    box-sizing: border-box;
                    background: #0d2786c4;
                    // text-shadow: 0px 0px 5px #00000070;

                    span {
                        margin-top: 10px;
                        font-size: 16px;
                        // line-height: 40px;
                    }
                }
            }
            .box-bg1 {
                background-image: url("../../../assets/img/company/bg3.png");
                background-position: 100% 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .box-bg2 {
                background-image: url("../../../assets/img/company/bg3.jpg");
                background-position: 100% 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .box-bg3 {
                background-image: url("../../../assets/img/company/bg2.jpg");
                background-position: 20% 100%;
                background-size: 200% 100%;
                background-repeat: no-repeat;
            }
            .box-bg4 {
                background-image: url("../../../assets/img/company/bg1.jpg");
                background-position: 90% 100%;
                background-size: 300% 120%;
                background-repeat: no-repeat;
            }
            .box-bg5 {
                background-image: url("../../../assets/img/company/bg5.jpg");
                background-position: 100% 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
    }
}
</style>