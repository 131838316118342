<template>
    <div class="product">
        <div class="product-left">
            <div class="box">
                <div>
                    <strong>PRODUCT</strong>
                </div>
                <div><strong>产品展示</strong></div>
                <p @click="lineTo('ProductCenter/huanbaoyushuikou')">
                    海绵城市设施
                </p>
                <p @click="lineTo('ProductCenter/liuliangji')">监测设备</p>
                <p @click="lineTo('ProductCenter/zhihuishuiwu')">水务系统</p>
                <p @click="lineTo('ProductCenter/huanbaoyushuikou')">
                    查看更多
                </p>
            </div>
        </div>
        <div class="product-right">
            <div class="product-right-item">
                <div
                    class="bgimg type1"
                    @click="lineTo('ProductCenter/huanbaoyushuikou')"
                ></div>
                <div class="item-text"><strong>环保式雨水口</strong></div>
            </div>
            <div class="product-right-item">
                <div
                    class="bgimg type2"
                    @click="lineTo('ProductCenter/huanbaoyushuikou')"
                ></div>
                <div class="item-text"><strong>双篦式环保式雨水口</strong></div>
            </div>
            <div class="product-right-item">
                <div
                    class="bgimg type3"
                    @click="lineTo('ProductCenter/liuliangji')"
                ></div>
                <div class="item-text"><strong>声学多普勒流量计</strong></div>
            </div>
            <div class="product-right-item">
                <div
                    class="bgimg type4"
                    @click="lineTo('ProductCenter/leidayeweiji')"
                ></div>
                <div class="item-text"><strong>雷达液位计</strong></div>
            </div>
            <div
                class="product-right-item"
                @click="lineTo('ProductCenter/yunweiguanli')"
            >
                <div class="bgimg type5"></div>
                <div class="item-text">
                    <strong>运维管理平台</strong>
                </div>
            </div>
            <div
                class="product-right-item"
                @click="lineTo('ProductCenter/zhihuihaimian')"
            >
                <div class="bgimg type6"></div>
                <div class="item-text">
                    <strong>智慧海绵管理平台</strong>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BannerProduct",
    methods: {
        lineTo(data) {
            this.$parent.$parent.$children[0].lineTo(data);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/mixin.scss";
.product {
    display: flex;
    width: 100%;
    height: 100%;
    .product-left {
        display: flex;
        justify-content: right;
        width: 35%;
        background-image: url("../../../assets/img/bg3.png");
        // background-position: 50% 0%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-top: 6%;
        .box {
            width: 400px;
            height: 100%;
            div:nth-child(1) {
                font-size: 72px;
                color: #ffffff3d;
            }
            div:nth-child(2) {
                margin-top: 10px;
                font-size: 36px;
                color: #ffffff;
            }
            div:nth-child(2) {
                margin-top: 10px;
                margin-bottom: 70px;
                font-size: 36px;
                color: #ffffff;
            }
            p {
                margin: 20px 0;
                font-size: 24px;
                color: #fff;
                cursor: pointer;
            }
        }
    }
    .product-right {
        display: flex;
        flex-wrap: wrap;
        width: 65%;
        background: #eeeeee;
        padding: 10% 9% 10% 2%;
        box-sizing: border-box;
        .product-right-item {
            width: 305px;
            height: 370px;
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                width: 275px;
                height: 275px;
            }
            .bgimg {
                width: 275px;
                height: 275px;
            }
            .item-text {
                margin-top: 20px;
                font-size: 20px;
            }
            .type1 {
                background: url("../../../assets/img/product/huanbaoyushuikou.jpg")
                    no-repeat center;
                background-size: 100% 100%;
                cursor: pointer;
                &:hover {
                    animation: amplification 1s;
                }
            }
            .type2 {
                background: url("../../../assets/img/product/shuangbishihuanbaoyushuikou.jpg")
                    no-repeat center;
                background-size: 100% 100%;
                cursor: pointer;
                &:hover {
                    animation: amplification 1s;
                }
            }
            .type3 {
                background: url("../../../assets/img/product/liuliangji.png")
                    no-repeat center;
                background-size: 100% 100%;
                cursor: pointer;
                &:hover {
                    animation: amplification 1s;
                }
            }
            .type4 {
                background: url("../../../assets/img/product/yeweiji.png")
                    no-repeat center;
                background-size: 100% 100%;
                cursor: pointer;
                &:hover {
                    animation: amplification 1s;
                }
            }
            .type5 {
                background: #fff
                    url("../../../assets/img/product/shundeweb.jpg") no-repeat
                    center;
                background-size: 100% 50%;
                cursor: pointer;
                animation: background-size 1s;
                &:hover {
                    animation: amplification2 1s;
                }
            }
            .type6 {
                background: #fff
                    url("../../../assets/img/product/szzhihuihaimian.png")
                    no-repeat center;
                background-size: 100% 50%;
                cursor: pointer;
                &:hover {
                    animation: amplification2 1s;
                }
            }
        }
    }
    // position: relative;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // width: 1600px;
    // height: 730px;
    // z-index: 1;
    // background-color: #fff;
    // background-image: url("../../../assets/img/bg2.png");
    // background-position: 50% 0%;
    // background-size: 80% 80%;
    // background-repeat: no-repeat;
}
@keyframes amplification {
    100% {
        background-size: 110% 110%;
    }
}
@keyframes amplification2 {
    100% {
        background-size: 110% 60%;
    }
}
</style>