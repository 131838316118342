var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',{staticClass:"product-left"},[_c('div',{staticClass:"box"},[_vm._m(0),_vm._m(1),_c('p',{on:{"click":function($event){return _vm.lineTo('ProductCenter/huanbaoyushuikou')}}},[_vm._v(" 海绵城市设施 ")]),_c('p',{on:{"click":function($event){return _vm.lineTo('ProductCenter/liuliangji')}}},[_vm._v("监测设备")]),_c('p',{on:{"click":function($event){return _vm.lineTo('ProductCenter/zhihuishuiwu')}}},[_vm._v("水务系统")]),_c('p',{on:{"click":function($event){return _vm.lineTo('ProductCenter/huanbaoyushuikou')}}},[_vm._v(" 查看更多 ")])])]),_c('div',{staticClass:"product-right"},[_c('div',{staticClass:"product-right-item"},[_c('div',{staticClass:"bgimg type1",on:{"click":function($event){return _vm.lineTo('ProductCenter/huanbaoyushuikou')}}}),_vm._m(2)]),_c('div',{staticClass:"product-right-item"},[_c('div',{staticClass:"bgimg type2",on:{"click":function($event){return _vm.lineTo('ProductCenter/huanbaoyushuikou')}}}),_vm._m(3)]),_c('div',{staticClass:"product-right-item"},[_c('div',{staticClass:"bgimg type3",on:{"click":function($event){return _vm.lineTo('ProductCenter/liuliangji')}}}),_vm._m(4)]),_c('div',{staticClass:"product-right-item"},[_c('div',{staticClass:"bgimg type4",on:{"click":function($event){return _vm.lineTo('ProductCenter/leidayeweiji')}}}),_vm._m(5)]),_c('div',{staticClass:"product-right-item",on:{"click":function($event){return _vm.lineTo('ProductCenter/yunweiguanli')}}},[_c('div',{staticClass:"bgimg type5"}),_vm._m(6)]),_c('div',{staticClass:"product-right-item",on:{"click":function($event){return _vm.lineTo('ProductCenter/zhihuihaimian')}}},[_c('div',{staticClass:"bgimg type6"}),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_vm._v("PRODUCT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_vm._v("产品展示")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-text"},[_c('strong',[_vm._v("环保式雨水口")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-text"},[_c('strong',[_vm._v("双篦式环保式雨水口")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-text"},[_c('strong',[_vm._v("声学多普勒流量计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-text"},[_c('strong',[_vm._v("雷达液位计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-text"},[_c('strong',[_vm._v("运维管理平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-text"},[_c('strong',[_vm._v("智慧海绵管理平台")])])
}]

export { render, staticRenderFns }