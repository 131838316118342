<template>
    <div class="main">
        <!-- <div class="banner-swiper"> -->
        <div class="swiper swiper-no-swiping">
            <div class="swiper-wrapper">
                <div class="swiper-slide bg1"></div>
                <div class="swiper-slide bg2">
                    <banner-first></banner-first>
                </div>
                <div class="swiper-slide">
                    <banner-product></banner-product>
                </div>
                <div class="swiper-slide bg3">
                    <banner-instance></banner-instance>
                </div>
                <!-- <div class="swiper-slide bg2"></div> -->
                <div class="swiper-slide"><nav-footer></nav-footer></div>
            </div>
            <!-- 分页器 -->
            <div class="swiper-pagination"></div>
        </div>
        <!-- </div> -->
    </div>
</template>
<script>
import Swiper from "swiper/swiper-bundle.js";
import "swiper/swiper-bundle.css";

import NavFooter from "../../components/NavFooter.vue";
import BannerFirst from "./components/bannerFirst.vue";
import BannerProduct from "./components/bannerProduct.vue";
import BannerInstance from "./components/bannerInstance.vue";

export default {
    name: "CustomSwiper",
    components: {
        NavFooter,
        BannerFirst,
        BannerProduct,
        BannerInstance,
    },
    async mounted() {
        new Swiper(".swiper", {
            speed: 1000,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            resistanceRatio: 0,
            loop: false,
            autoplay: false,
            mousewheel: true,
            direction: "vertical",
            duration: 3000,
            // effect: "fade",
        });
    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/mixin.scss";
.main {
    // position: relative;
    width: 100%;
    height: 100%;
    // min-height: 937px;
    z-index: 0;
    .swiper {
        // width: 100%;
        // height: 972px;
        // height: 100vh;
        // height: 937px;
        // min-height: 937px;
        width: 100%;
        // min-width: 1920px;
        // min-height: 937px;
        height: 100vh;
        .swiper-slide {
            // width: 100%;
            // height: 100%;
        }
        .bg1 {
            @include bgImg(100%, 100%, "../../assets/img/bg1.png", cover);
            background-attachment: fixed;
        }
        .bg2 {
            @include bgImg(100%, 100%, "../../assets/img/bg5.png", cover);
            background-attachment: fixed;
            // background-color: rgb(94, 156, 196);
            // background-image: url("../../assets/img/bg2.png");
            // background-position: 50% 50%;
            // background-repeat: no-repeat;
        }
        .bg3 {
            @include bgImg(100%, 100%, "../../assets/img/bg4.jpg", cover);
            background-attachment: fixed;
        }
        .bg4 {
            @include bgImg(100%, 100%, "../../assets/img/bg2.jpg", cover);
            background-attachment: fixed;
        }
    }
}
</style>