<template>
    <div class="main1">
        <strong class="main1-title">关于我们</strong>
        <div class="text-list">
            <p>
                深圳市创环环保科技有限公司成立于2010年，一直致力于为智慧水务、海绵城市建设、城市内涝及水环境改善提供整体解决方案。
            </p>
            <p>
                ●
                流域管理数学模型的开发，为各类智慧水务类管理平台提供数据服务及数据分析
            </p>
            <p>● 水务数据信息化服务，管道测绘、检测、修复、运维及GIS系统构建</p>
            <p>
                ●
                流域水环境治理及海绵城市建设规划、设计、施工及技术咨询（专项规划、技术指引编制等）
            </p>
            <p>● 水环境治理及海绵城市建设技术及产品研发、集成</p>
        </div>
        <div class="box-list">
            <div class="box-list-item">
                <strong class="num">6+</strong>
                <span>专业管理系统</span>
            </div>
            <div class="box-list-item">
                <strong class="num">20+</strong>
                <span>城市水务模型制作 </span>
            </div>
            <div class="box-list-item">
                <strong class="num">300+</strong>
                <span>管网水质水量监测 </span>
            </div>
            <div class="box-list-item">
                <strong class="num">3000<span>公里</span>+</strong>
                <span>管网测绘、检测、运维 </span>
            </div>
            <div class="box-list-item">
                <strong class="num">3<span>万户</span>+</strong>
                <span>排水户专项排查 </span>
            </div>
            <div class="box-list-item">
                <strong class="num">10+</strong>
                <span>国家及地方技术标准、规范指引</span>
            </div>
            <div class="box-list-item">
                <strong class="num">50+</strong>
                <span>海绵城市技术咨询及施工 </span>
            </div>
        </div>
        <!-- <video src=""></video> -->
    </div>
</template>
<script>
export default {
    name: "BannerFirst",
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/mixin.scss";
.main1 {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1600px;
    height: 730px;
    padding: 100px 0 0 580px;
    z-index: 1;
    background-color: #fff;
    background-image: url("../../../assets/img/bg2.png");
    background-position: 50% 0%;
    background-size: 80% 80%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    .main1-title {
        // position: absolute;
        font-size: 36px;
        // margin-left: 580px;
        // margin-top: 300px;
        // left: 580px;
        // top: 100px;
    }
    .text-list {
        width: 800px;
        line-height: 30px;
        font-size: 16px;
        margin-top: 0px;
        // position: absolute;
        // left: 580px;
        // top: 170px;
        p:nth-child(1) {
            box-sizing: border-box;
            padding-left: 0px;
            line-height: 50px;
        }
        p {
            box-sizing: border-box;
            padding-left: 20px;
            line-height: 50px;
        }
    }
    .box-list {
        display: flex;
        flex-wrap: wrap;
        width: 960px;
        margin-top: 20px;
        // position: absolute;
        // left: 580px;
        // top: 470px;
        .box-list-item {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 240px;
            height: 110px;
            .num {
                display: block;
                height: 60px;
                line-height: 60px;
                font-size: 50px;
                // color: rgb(38, 23, 211);
            }
            span {
                font-size: 16px;
            }
        }
    }
    video {
        width: 900px;
        height: 400px;
        position: absolute;
        left: 580px;
        top: 270px;
        background: #bcbcbc;
    }
}
</style>